/* Home.css */
.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

.auth-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.auth-status {
  font-size: 24px;
  color: #4caf50;
}

.auth-status.not-auth {
  color: #f44336;
}

.links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.links-container .btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.links-container .btn:hover {
  background-color: #0056b3;
}

.sign-out-btn {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.sign-out-btn:hover {
  background-color: #d32f2f;
}

