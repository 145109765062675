.outfit-update-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.outfit-preview {
  position: relative;
  width: 300px; /* Adjust the size as needed */
  height: 300px; /* Ensure it's a square */
}

.outfit-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.outfit-selectors {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outfit-category {
  margin: 10px 0;
  text-align: center;
}

.outfit-description {
  display: flex;
  align-items: center;
  justify-content: center;
}

.outfit-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px; /* Space between buttons and outfit details */
}

.outfit-icon {
  max-width: 100px; /* Adjust size as needed */
  height: auto;
  display: block;
}

.outfit-label {
  text-align: center;
  margin-top: 5px; /* Space between image and label */
}

.outfit-nav-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav-arrow {
  width: 24px; /* Adjust size as needed */
  height: auto;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.outfit-nav-button:hover .nav-arrow {
  transform: scale(1.1);
  opacity: 0.8;
}

.outfit-nav-button:active .nav-arrow {
  transform: scale(0.9);
  opacity: 1;
}

.done-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.done-button:hover {
  background-color: #218838;
}

.done-button:active {
  background-color: #1e7e34;
}

