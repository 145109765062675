/* RoomList.css */
.room-list-container {
  padding: 16px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.room-list-title {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: #333;
  text-align: center;
}

.room-list {
  list-style-type: none;
  padding: 0;
}

.room-list-item {
  padding: 12px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.room-list-item:hover {
  background-color: #f1f1f1;
}

.room-id {
  font-weight: bold;
  color: #007bff;
}

.room-title {
  font-weight: normal;
  color: #555;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .room-list-container {
    padding: 12px;
  }

  .room-list-title {
    font-size: 1.25rem;
  }

  .room-list-item {
    padding: 10px;
    font-size: 1rem;
  }
}

