.bracelet-foundry {
  padding: 20px;
}

.create-bracelet-section,
.search-section {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.bracelet-management-buttons {
  margin: 20px 0;
  display: flex;
  gap: 10px;
}

.bracelet-management-buttons button {
  padding: 10px 15px;
  font-size: 14px;
}

.bracelet-table {
  width: 100%;
  border-collapse: collapse;
}

.bracelet-table th,
.bracelet-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.bracelet-table th {
  background-color: #f4f4f4;
}

.checkbox-indicator {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  border-radius: 4px;
}

.checked {
  background-color: blue;
  color: white;
}

.unchecked {
  background-color: lightgray;
  color: gray;
}

.table-row {
  cursor: pointer;
}

.table-row * {
  cursor: text;
}

.table-row:hover {
  cursor: pointer;
}

.table-row *:hover {
  cursor: text;
}

