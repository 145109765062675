/* Creation.css */
.creation-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.create-post {
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  color: #007bff; /* Text color */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none; /* Removes underline */
  border-radius: 5px;
  border: 2px solid #007bff; /* Adds a blue border */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-post:hover {
  background-color: #f0f0f0; /* Slightly grey background on hover */
  transform: scale(1.05);
}

.create-post:active {
  background-color: #e0e0e0; /* Slightly darker grey background when clicked */
}

.creation-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.creation-form {
  display: flex;
  flex-direction: column;
}

.form-input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-input[type="file"] {
  padding: 5px;
}

.submit-button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .creation-container {
    padding: 16px;
  }

  .creation-title {
    font-size: 1.5rem;
  }

  .form-input {
    font-size: 0.9rem;
  }

  .submit-button {
    font-size: 0.9rem;
  }
}

