.character-view {
  position: relative;
  width: 200px; /* Adjust size as needed */
  height: 200px; /* Make sure it's square */
}

.character-view img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
