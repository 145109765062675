/* Header.css */
.header-container {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #007bff;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.back-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  margin-right: 16px;
}

.header-title {
  font-size: 1.5rem;
  flex-grow: 1;
  text-align: center;
  margin: 0;
}

