.room-container {
  padding: 20px;
}

.room-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.create-post {
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  color: #007bff; /* Text color */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid #007bff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-post:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.posts-list {
  list-style-type: none;
  padding: 0;
}

.post-item {
  position: relative;
  margin-bottom: 20px;
}

.post-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.post-author {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  z-index: 2;
}

.author-profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: 10px;
}

.author-username {
  color: white;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.post-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0; /* Remove padding */
  color: white;
  font-size: 14px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2), transparent);
  border-radius: 0 0 8px 8px;
  margin: 0; /* Ensure no margin */
  height: auto; /* Ensure it covers the content fully */
}

.post-description-text {
  color: white;
  font-size: 14px;
  margin: 20px; /* Ensure no margin */
  height: auto; /* Ensure it covers the content fully */
}
