.bracelet-registration-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bracelet-registration-container h2,
.bracelet-registration-container h3 {
  text-align: center;
}

.bracelet-registration-container input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.bracelet-registration-container button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.bracelet-registration-container button:hover {
  background-color: #218838;
}

.rules-section {
  margin-top: 20px;
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 4px;
}

